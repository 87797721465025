import request from '@/utils/request'

//获取指令列表
export function getCmdList(data){
    return request({
        url:'/instructConfig/pageInstruct',
        method:'get',
        params:data
    })
}
//删除指令
export function deleteCmd(data){
    return request({
        url:'/instructConfig/deleteInstruct',
        method:'get',
        params:data
    })
}
// 新增指令
export function addInstructCmd(data){
    return request({
        url:'/instructConfig/saveInstruct',
        method:'post',
        data:data
    })
}
// 编辑指令
export function updateInstructCmd(data){
    return request({
        url:'/config/cmd/updateInstructCmd',
        method:'post',
        data:data
    })
}
//新增指令参数
export function addInstructCmdParam(data){
    return request({
        url:'/config/cmd/addInstructCmdParam',
        method:'post',
        data:data
    })
}
//更新指令参数
export function updateInstructCmdParam(data){
    return request({
        url:'/config/cmd/updateInstructCmdParam',
        method:'post',
        data:data
    })
}
//删除指令参数
export function deleteInstructCmdParam(data){
    return request({
        url:'/config/cmd/deleteInstructCmdParam/'+data,
        method:'post',
        data:data
    })
}