<template>
    <div class="home">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery">
                    <div class="form">
                        <div class="search-btns">
                            <tem-btn type="primary" value="添加" @click="addCmd()"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderBy" @pagination="getTableList"></Table>
        </div>
    
    
        <el-dialog :title="titleMap[dialogTitle]" :visible.sync="addInstructCmdFormVisible" custom-class="dialogClass" width="600px">
            <el-form :model="addInstructCmdForm" ref="addInstructCmdForm" :label-width="formLabelWidth1" >
                <el-form-item label="指令名称" prop="instructName"  :rules="{required:true,message:'指令名称不能为空',trigger:'blur'}">
                    <el-input v-model="addInstructCmdForm.instructName" autocomplete="off" class="input-w290-h32" ></el-input>
                </el-form-item>
                <el-form-item label="指令内容" prop="instructContent"  :rules="{required:true,message:'指令内容不能为空',trigger:'blur'}">
                    <el-input v-model="addInstructCmdForm.instructContent" autocomplete="off" class="input-w290-h32" ></el-input>
                </el-form-item>
                <!-- <el-form-item label="指令类型" prop="instructType"  :rules="{required:true,message:'请选择',trigger:'change'}">
                    <el-select v-model="addInstructCmdForm.instructType" placeholder="请选择" class="input-w290-h32">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="协议号" prop="protocolCode"  :rules="{required:true,message:'协议号不能为空',trigger:'blur'}">
                    <el-input v-model="addInstructCmdForm.protocolCode" autocomplete="off" class="input-w290-h32" ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn type="info" value="取消" @click="resetForm('addInstructCmdForm')"></tem-btn>
                <tem-btn type="primary" value="确定" @click="addInstructCmdSubmitForm('addInstructCmdForm')"></tem-btn>
            </div>
        </el-dialog>
        
        <el-dialog title="设置指令参数" :visible.sync="dialogFormVisible" custom-class="dialogClass" width="600px">
            <el-form :model="form"  :label-width="formLabelWidth1" >
                <el-form-item label="指令顺序" >
                {{form.id}}
                </el-form-item>
                <el-form-item label="指令名称" >
                {{form.name}}
                </el-form-item>
                <el-form-item label="指令内容" >
                {{form.content}}
                </el-form-item>
                <el-button @click="dialogFormVisible2 = true" type="text" style="margin-left:50px">添加参数</el-button>  
                <div v-for="(item, index) in form.domains" 
                :key="index" 
                style="overflow:hidden">
                    <el-form-item 
                        :label="item.paramName" 
                        :prop="'domains.'+index+'.defaultValue'" 
                        :rules="{required:true,message:'内容不能为空',trigger:'blur'}"  
                        style="float:left">
                        <el-input v-if="item.paramType == 'text'" :disabled="true" v-model="item.defaultValue" autocomplete="off" class="input-w290-h32"></el-input>
                        <el-input v-else-if="item.paramType == 'input'" v-model="item.defaultValue" autocomplete="off" class="input-w290-h32"></el-input>
                        <el-input v-else-if="item.paramType == 'tel'" v-model="item.defaultValue" autocomplete="off" class="input-w290-h32"></el-input>
                        <el-input v-else-if="item.paramType == 'nub'" v-model="item.defaultValue" autocomplete="off" class="input-w290-h32"></el-input>
                        <el-input v-else-if="item.paramType == 'email'" v-model="item.defaultValue" autocomplete="off" class="input-w290-h32"></el-input>
                        <el-input v-else-if="item.paramType == 'password'" v-model="item.defaultValue" autocomplete="off" class="input-w290-h32"></el-input>
                        <el-radio-group v-else-if="item.paramType == 'radio'" v-model="item.defaultValue">
                            <el-radio-button v-for="(opt,index) in JSON.parse(item.paramValue)" :key="index" :label="opt.val">{{opt.name}}</el-radio-button>
                        </el-radio-group>
                        <el-checkbox-group v-else-if="item.paramType == 'checkbox'" v-model="item.defaultValue">
                            <el-checkbox v-for="(opt,index) in JSON.parse(item.paramValue)" :label="opt.val" :key="index">{{opt.name}}</el-checkbox>
                        </el-checkbox-group>
                        <el-select v-else-if="item.paramType == 'select'" v-model="item.defaultValue" placeholder="请选择">
                            <el-option
                                v-for="(opt,index) in JSON.parse(item.paramValue)"
                                :key="index"
                                :label="opt.name"
                                :value="opt.val">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-button @click.prevent="deleteInstructCmdParam(item)" type="text" style="margin:0 10px">删除</el-button>
                    说明：{{item.paramMsg}}
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn type="info" value="取消" @click="dialogFormVisible = false"></tem-btn>
                <tem-btn type="primary" value="确定" @click="dialogFormVisible = false"></tem-btn>
            </div>
        </el-dialog>

        <el-dialog title="参数值编辑" :visible.sync="dialogFormVisible2" width="400px">
            <el-form :model="form2"  :rules="rules2" ref="form2"  :label-width="formLabelWidth2">
                <el-form-item label="标题"  prop="name">
                    <el-input v-model="form2.name" autocomplete="off"  class="input-w180-h32" style="width:210px"></el-input>
                </el-form-item>
                <el-form-item label="类型"  prop="type">
                    <el-select v-model="form2.type" placeholder="请选择" @change="typeSelectChange" class="input-w180-h32" style="width:210px">
                        <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="默认值"  prop="val">
                    <el-input v-model="form2.val" autocomplete="off" class="input-w180-h32" style="width:210px"></el-input>
                </el-form-item>
                <el-form-item label="参数说明"  prop="info">
                    <el-input v-model="form2.info" autocomplete="off" class="input-w180-h32" style="width:210px"></el-input>
                </el-form-item>
                <div v-if="showVal">
                    <el-button @click="addDomain2()" type="text" style="margin-left:13px">添加值</el-button>
                    <div class="moreRulesIn" v-for="(item, index) in form2.domains" :key="item.index" style="overflow:hidden">
                        <el-form-item :label="'内容'+index" :prop="'domains.'+index+'.name'" :rules="{required:true,message:'内容不能为空',trigger:'blur'}"  style="float:left">
                            <el-input v-model="item.name" autocomplete="off" class="input-w180-h32" style="width:70px"></el-input>
                        </el-form-item>
                        <el-form-item label="值" :prop="'domains.'+index+'.val'" :rules="{required:true,message:'值不能为空',trigger:'blur'}" style="float:left" label-width="50px" >
                            <el-input  v-model="item.val" autocomplete="off" class="input-w180-h32" style="width:70px"></el-input>
                        </el-form-item>
                        <el-button @click.prevent="removeDomain2(domain)" type="text" style="float:left;margin-left:10px">移除</el-button>
                    </div>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn type="info" value="取消" @click="resetForm('form2')"></tem-btn>
                <tem-btn type="primary" value="确定" @click="submitForm2('form2')"></tem-btn>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import {getCmdList,deleteCmd,addInstructCmd,updateInstructCmd,addInstructCmdParam,updateInstructCmdParam,deleteInstructCmdParam} from "@/api/equipment";
import Table from '@/components/table/table';
export default {
    components: {
        Table
    },
    data() {
        return {
            //搜索栏数据
            listQuery:{
                total:10000,//数据条数
                pageIndex: 1,//当前查询页面
                pageSize: 10,//每页数据数量
                orderBy:''
            },
            options: [{
                value: 'CUSTOM_INS',
                label: '自定义指令'
            }, {
                value: 'NORMAL_INS',
                label: '常规指令'
            }],
            //表格数据
            table: {
                configItem: true, //是否开启表格项配置
                data: [],
                header: [
                    {
                        type: 'index',
                        label: '序号',
                        width: '55',
                        ifShow: true,
                    },
                    {
                        prop: 'id',
                        label: '指令id',
                        minWidth: '180',
                        ifShow: false,
                    },
                    {
                        prop: 'instructName',
                        label: '指令名称',
                        minWidth: '180',
                        ifShow: true,
                    },
                    {
                        prop: 'instructContent',
                        label: '指令内容',
                        minWidth: '180',
                        ifShow: true,
                    },
                    {
                        prop: 'instructType',
                        label: '指令类型',
                        minWidth: '180',
                        ifShow: true,
                    },
                    {
                        prop: 'protocolCode',
                        label: '协议号',
                        minWidth: '180',
                        ifShow: true,
                    },
                    // {
                    //     prop: 'instructParamVOList',
                    //     label: '指令参数',
                    //     minWidth: '180',
                    //     ifShow: true,
                    // },
                    {
                        prop: 'options',
                        label: '操作',
                        minWidth: '180',
                        ifShow: true,
                        fixed: 'right',
                        render:(h,param) => {
                            return h('div', [
                                h(
                                    'el-tooltip', {
                                        props: {
                                            effect:'dark',
                                            content:'编辑',
                                            placement:'top'
                                        }
                                    },
                                    [h('span',{
                                        style:{
                                            cursor:'pointer'
                                        },
                                        on:{
                                            click:()=> {
                                                this.updataCmd(param);
                                            }
                                        }
                                    },[
                                        h('svg-icon',{
                                            props:{
                                                iconClass:'icon-edit',
                                                size: '16',
                                                className:'icon'
                                            },
                                        })
                                    ])]
                                ),
                                h(
                                    'el-tooltip', {
                                        props: {
                                            effect:'dark',
                                            content:'删除',
                                            placement:'top',
                                            
                                        }
                                    },
                                    [h('span',{
                                        style:{
                                            cursor:'pointer',
                                            marginLeft: '20px',
                                        },
                                        on:{
                                            click:()=> {
                                                this.deleteCmd(param);
                                            }
                                        }
                                    },[
                                        h('svg-icon',{
                                            props:{
                                                iconClass:'icon-del',
                                                size: '16',
                                                className:'icon'
                                            }
                                        })
                                    ])]
                                ),
                                h('el-button',{
                                    style:{
                                        marginLeft: '20px',
                                        width: '84px',
                                        height: '32px',
                                        lineHeight: '32px',
                                        padding: '0',

                                    },
                                    on:{
                                        click: () => {
                                            this.setCmd(param);
                                        }
                                    }
                                },'设置参数')
                            
                            ]);
                        }
                    }
                ],
            },
            titleMap:{
                addData : "添加指令",
                updateData : "修改指令"
            },
            dialogTitle:'',
            tableData: [],
            addInstructCmdForm:{
                id:'',
                instructName:'',
                instructContent:'',
                protocolCode:'',
                instructType:'',
                instructParamVOList:[],
                instructParamQueryList:[]
            },
            addInstructCmdFormVisible:false,
            dialogFormVisible: false,
            form: {
                id:'',
                name:'',
                content:'',
                domains: [],
            },
            showVal:false,
            formLabelWidth1: '120px',
            dialogFormVisible2: false,
            form2: {
                name: '',
                type: '',
                val: '',
                info: '',
                domains:[]
            }, 
            rules2:{
                name:[{ required: true, message: '请输入标题', trigger: 'blur' }],
                type:[{ required: true, message: '请选择类型', trigger: 'change' }]
            },
            typeOptions: [{
                value: 'text',
                label: '文本'
                }, {
                value: 'input',
                label: '文本框'
                }, {
                value: 'tel',
                label: '电话'
                }, {
                value: 'nub',
                label: '数字'
                }, {
                value: 'email',
                label: '邮箱'
                },{
                value: 'password',
                label: '密码'
                },{
                value: 'radio',
                label: '单选框'
                },{
                value: 'checkbox',
                label: '复选框'
                },{
                value: 'select',
                label: '下拉框'
                }],
            formLabelWidth2: '70px'
        }
    },
  created(){
    this.getTableList();
  },
  methods:{
    /**
     * 获取表格数据
     */
    getTableList(){
        getCmdList(this.listQuery).then( res => {
            if( res.code === 200 ) {
                this.table.data = res.data;
                this.listQuery.total = res.count;
            }
        })
    },
    addCmd(){
      //添加指令
      this.addInstructCmdForm = {
                id:'',
                instructName:'',
                instructContent:'',
                protocolCode:'',
                instructType:'',
                instructParamVOList:[]
            },
      this.dialogTitle = "addData";
      this.addInstructCmdFormVisible = true;
    },
    updataCmd(row){
        //编辑指令
        this.addInstructCmdForm.id = row.id;
        this.addInstructCmdForm.instructName = row.instructName;
        this.addInstructCmdForm.instructContent = row.instructContent;
        this.addInstructCmdForm.instructType = row.instructType;
        this.addInstructCmdForm.protocolCode = row.protocolCode;
        this.addInstructCmdForm.instructParamVOList = JSON.parse(JSON.stringify(row.instructParamVOList));
        this.addInstructCmdForm.instructParamQueryList = JSON.parse(JSON.stringify(row.instructParamVOList));
        this.dialogTitle = "updateData";
        this.addInstructCmdFormVisible = true;
    },
    deleteCmd(data){
      deleteCmd({id:data.id}).then(res => {
        // console.log(res);
        this.getTableList();
      })
    },
    setCmd(row){
        //设置参数
        this.addInstructCmdForm.id = row.id;
        this.addInstructCmdForm.instructName = row.instructName;
        this.addInstructCmdForm.instructContent = row.instructContent;
        this.addInstructCmdForm.instructType = row.instructType;
        this.addInstructCmdForm.protocolCode = row.protocolCode;
        this.addInstructCmdForm.instructParamVOList = JSON.parse(JSON.stringify(row.instructParamVOList));
        this.addInstructCmdForm.instructParamQueryList = JSON.parse(JSON.stringify(row.instructParamVOList));
        this.form.id = row.id;
        this.form.name = row.instructName;
        this.form.content = row.instructContent;
        var ss = row.instructParamVOList;
        for(var i = 0;i < ss.length;i++){ 
            if(ss[i].paramType == "checkbox" && typeof(ss[i].defaultValue)=='string'){
                ss[i].defaultValue = JSON.parse(ss[i].defaultValue)
            }
        }
        // console.log(ss)
        this.form.domains = ss;
        this.dialogFormVisible = true
    },
    szzlcsFormSubmit(){
      console.log(this.form)
      for(var i = 0;i < this.form.domains.length;i++){
        if(this.form.domains[i].paramType == "checkbox"){
          //修改指令参数
          updateInstructCmdParam({
            "id":this.form.domains[i].id,
            "defaultValue":JSON.stringify(this.form.domains[i].defaultValue),
          }).then(res => {
            // console.log(res);
          })
        }else{
          //修改指令参数
          updateInstructCmdParam({
            "id":this.form.domains[i].id,
            "defaultValue":this.form.domains[i].defaultValue,
          }).then(res => {
            // console.log(res);
          })
        }
      }
      this.dialogFormVisible = false;
    },
    resetForm(formName) { 
        this.$refs[formName].resetFields();
        if(formName == 'addInstructCmdForm'){
          this.addInstructCmdFormVisible = false;
        }else{
          this.dialogFormVisible2 = false;
        }
    },
    addInstructCmdSubmitForm(formName){
      var _ = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogTitle == "addData") {
            //添加指令 
            addInstructCmd({
                instructContent:_.addInstructCmdForm.instructContent,
                instructName:_.addInstructCmdForm.instructName,
                instructType:_.addInstructCmdForm.instructType,
                protocolCode:_.addInstructCmdForm.protocolCode
            }).then(res => {
              // console.log(res);
              _.getTableList();
              _.addInstructCmdFormVisible = false;
              _.$refs[formName].resetFields();
            })
          }else{
            //编辑指令
            addInstructCmd(_.addInstructCmdForm).then(res => {
              // console.log(res);
              _.getTableList();
              _.addInstructCmdFormVisible = false;
              _.$refs[formName].resetFields();
            })
          }
          
        }
      })
    },
    deleteInstructCmdParam(data) {
        var _ = this;
        // console.log(data) 
        //删除指令参数
        //   deleteInstructCmdParam(item.id).then(res => {
        //     var index = this.form.domains.indexOf(item)
        //     if (index !== -1) {
        //       this.form.domains.splice(index, 1)
        //     }
        //   })
        _.addInstructCmdForm.instructParamQueryList.splice(_.addInstructCmdForm.instructParamQueryList.findIndex(item=>item.paramName == data.paramName),1)
        // // console.log(_.form.domains)
        addInstructCmd(_.addInstructCmdForm).then(res => {
            _.form.domains.splice(_.form.domains.findIndex(item=>item.paramName == data.paramName),1)
            _.dialogFormVisible2 = false;
            _.showVal = false;
            // _.$refs[formName].resetFields();
            // console.log(_.form) 
        })
    },
    submitForm2(formName) {
      var _ = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
            var ss = JSON.parse(JSON.stringify(_.form2));
            console.log(_.addInstructCmdForm)
            // _.addInstructCmdForm.instructParamQueryList = 
            //添加指令参数;
            if(ss.type == 'checkbox'){
                //复选框 val需要填数组
                addInstructCmd({
                    "id":this.form.id,
                    "instructParamQueryList.defaultValue":JSON.stringify([this.form2.val]),
                    "instructParamQueryList.paramMsg":this.form2.info,
                    "instructParamQueryList.paramName":this.form2.name,
                    //"instructParamQueryList.paramOrder":this.form2.domains.length+1,
                    "instructParamQueryList.paramType":this.form2.type,
                    "instructParamQueryList.paramValue":JSON.stringify(this.form2.domains),
                }).then(res => {
                    console.log(res)
                    _.form.domains.push({
                        id:res.data.data.id,
                        paramName: ss.name,
                        paramType:ss.type,
                        defaultValue:[ss.val],
                        paramMsg:ss.info,
                        paramValue:JSON.stringify(ss.domains)
                    });
                    _.dialogFormVisible2 = false;
                    _.showVal = false;
                    _.$refs[formName].resetFields();
                })
            }else{
                // var b = this.form2.domains.length;
                var a = {
                    // "indexFlag":b,
                    "defaultValue":this.form2.val,
                    "paramMsg":this.form2.info,
                    "paramName":this.form2.name,
                    "paramType":this.form2.type,
                    "paramValue":JSON.stringify(this.form2.domains),
                }
                _.addInstructCmdForm.instructParamQueryList.push(a)
                // console.log(_.form.domains)
                addInstructCmd(_.addInstructCmdForm).then(res => {
                    _.form.domains.push(a);
                    _.dialogFormVisible2 = false;
                    _.showVal = false;
                    _.$refs[formName].resetFields();
                    // console.log(_.form)
                })
            }
        }else{ 
            console.log('error submit!!');
            return false;
        }
      });
    },
    typeSelectChange(type){
      if(type == 'radio'||type == 'checkbox'||type == 'select'){
        this.resetForm2Val();
      }else{
        this.showVal = false;
      }
    },
    resetForm2Val(){
      this.form2.domains = [{
        name:"",
        val:""
      }];
      this.showVal = true;
    },
    addDomain2(){
      this.form2.domains.push({
        name:"",
        val:""
      })
    },
    removeDomain2(item) {
      var index = this.form2.domains.indexOf(item)
      if (index !== -1) {
        this.form2.domains.splice(index, 1)
      }
    }
  }
}
</script>

